import { call, put, takeLatest } from 'redux-saga/effects';
import { SETTING_SET_LOCALE } from '../constants/setting';

function* setLocale(action) {
    try {
    } catch (e) {
        console.error('[Setting Saga]', e);
    }
}

function* SettingSaga() {
    yield takeLatest(SETTING_SET_LOCALE, setLocale);
}

export default SettingSaga;
