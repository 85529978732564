import {
    CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
    CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED,
    CUSTOMER_ORGANIZATION_GET_COMPLETED,
    CUSTOMER_ORGANIZATION_GET_IS_LOADING,
    CUSTOMER_ORGANIZATION_GET_NOT_LOADING,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_IS_LOADING,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING
} from '../constants/statistics';

const initialState = {
    organization: {
        is_loading: false,
        data: {}
    },
    statistics_accounts_registered: {
        is_loading: false,
        data: {}
    },
    statistics_mind_health: {
        is_loading: false,
        data: {}
    },
    statistics_support_engaged: {
        is_loading: false,
        data: {}
    },
    statistics_care_credit: {
        is_loading: false,
        data: {}
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CUSTOMER_ORGANIZATION_GET_IS_LOADING:
            return {
                ...state,
                organization: {
                    data: {},
                    is_loading: true
                }
            };
        case CUSTOMER_ORGANIZATION_GET_NOT_LOADING:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    is_loading: false
                }
            };
        case CUSTOMER_ORGANIZATION_GET_COMPLETED:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    data: action.payload.organization || {}
                }
            };

        case CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED:
            return {
                ...state,
                statistics_accounts_registered: {
                    is_loading: false,
                    data: action.payload
                }
            };
        case CUSTOMER_ORGANIZATION_MIND_HEALTH_IS_LOADING:
            return {
                ...state,
                statistics_mind_health: {
                    data: {},
                    is_loading: true
                }
            };

        case CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED:
            return {
                ...state,
                statistics_mind_health: {
                    is_loading: false,
                    data: action.payload
                }
            };

        case CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING:
            return {
                ...state,
                statistics_support_engaged: {
                    data: {},
                    is_loading: true
                }
            };

        case CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED:
            return {
                ...state,
                statistics_support_engaged: {
                    is_loading: false,
                    data: action.payload
                }
            };

        case CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED: {
            return {
                ...state,
                statistics_care_credit: {
                    is_loading: false,
                    data: action.payload.care_credit
                }
            };
        }

        default:
            return state;
    }
}
