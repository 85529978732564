import { Routes, Route } from 'react-router-dom';
import { lazy } from 'react';

// Authentication
import ProtectedRoute from './protected';
import UnAuthorizedRoute from './unauthorized';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const PractitionerRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/PractitionerRegister')));
const AuthenticationResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ResetPassword')));

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const HumanCareCredit = Loadable(lazy(() => import('views/human-care/credit')));
const Statistics = Loadable(lazy(() => import('views/statistics/customer-detail')));

const Home = Loadable(lazy(() => import('views/home')));
const PractitionerAvailability = Loadable(lazy(() => import('views/practitioner/availability')));

// ==============================|| ROUTING RENDER ||============================== //

export default function AppRoutes() {
    return (
        <Routes>
            {/* Authenticated Routes */}
            <Route
                path={'/'}
                element={<MainLayout />}
                children={[
                    <Route
                        path={'/'}
                        element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        }
                    />,
                    <Route
                        path={'dashboard'}
                        children={[
                            <Route
                                path={'default'}
                                element={
                                    <ProtectedRoute>
                                        <DashboardDefault />
                                    </ProtectedRoute>
                                }
                            />
                        ]}
                    />,
                    <Route
                        path={'statistics'}
                        children={[
                            <Route
                                key={'default'}
                                path={'default'}
                                element={
                                    <ProtectedRoute>
                                        <Statistics />
                                    </ProtectedRoute>
                                }
                            />
                        ]}
                    />,
                    <Route
                        path={'practitioner'}
                        children={[
                            <Route
                                key={'availability'}
                                path={'availability'}
                                element={
                                    <ProtectedRoute>
                                        <PractitionerAvailability />
                                    </ProtectedRoute>
                                }
                            />
                        ]}
                    />,
                    <Route
                        path={'human-care'}
                        children={[
                            <Route
                                key={'credit'}
                                path={'credit'}
                                element={
                                    <ProtectedRoute>
                                        <HumanCareCredit />
                                    </ProtectedRoute>
                                }
                            />
                        ]}
                    />
                ]}
            />
            {/* Unauthenticated Routes */}
            <Route
                path={'/'}
                children={[
                    <Route
                        key={'login'}
                        path={'login'}
                        element={
                            <UnAuthorizedRoute>
                                <AuthLogin />
                            </UnAuthorizedRoute>
                        }
                    />
                ]}
            />
            <Route
                path={'/'}
                children={[
                    <Route
                        key={'register'}
                        path={'register'}
                        element={
                            <UnAuthorizedRoute>
                                <PractitionerRegister />
                            </UnAuthorizedRoute>
                        }
                    />
                ]}
            />
            <Route path="/password" children={[<Route key={'reset'} path={'reset/:token'} element={<AuthenticationResetPassword />} />]} />
        </Routes>
    );
}
