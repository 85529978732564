import {
    HUMAN_CARE_CREDIT_IS_LOADING,
    HUMAN_CARE_CREDIT_NOT_LOADING,
    HUMAN_CARE_CREDIT_FETCHED,
    HUMAN_CARE_TRANSACTIONS_IS_LOADING,
    HUMAN_CARE_TRANSACTIONS_NOT_LOADING,
    HUMAN_CARE_TRANSACTIONS_FETCHED
} from '../constants/human_care';

const initialState = {
    credit: {
        is_loading: false,
        data: null
    },
    transactions: {
        is_loading: false,
        data: []
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case HUMAN_CARE_CREDIT_IS_LOADING:
            return {
                ...state,
                credit: {
                    ...state.credit,
                    is_loading: true
                }
            };
        case HUMAN_CARE_CREDIT_NOT_LOADING:
            return {
                ...state,
                credit: {
                    ...state.credit,
                    is_loading: false
                }
            };
        case HUMAN_CARE_CREDIT_FETCHED:
            return {
                ...state,
                credit: {
                    ...state.credit,
                    data: action.payload.credit
                }
            };
        case HUMAN_CARE_TRANSACTIONS_IS_LOADING:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    is_loading: true
                }
            };
        case HUMAN_CARE_TRANSACTIONS_NOT_LOADING:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    is_loading: false
                }
            };
        case HUMAN_CARE_TRANSACTIONS_FETCHED:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    data: action.payload.transactions ?? []
                }
            };
        default:
            return state;
    }
}
