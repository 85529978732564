// assets
import { IconCoin } from '@tabler/icons';
import { IconDashboard } from '@tabler/icons';

// constant
const icons = {
    IconCoin,
    IconDashboard
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const practitioner = {
    id: 'practitioner',
    title: 'Practitioner',
    type: 'group',
    children: [
        // {
        //     id: 'profile',
        //     title: 'Profile',
        //     type: 'item',
        //     url: '/practitioner/profile'
        // icon: icons.IconCoin
        // },
        {
            id: 'availability',
            title: 'Availability',
            type: 'item',
            url: '/practitioner/availability',
            icon: icons.IconDashboard
        }
    ]
};

export default practitioner;
