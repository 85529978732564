import { call, put, takeLatest } from 'redux-saga/effects';
import {
    AUTHENTICATION_REQUESTED,
    AUTHENTICATION_LOGIN_REQUESTED,
    AUTHENTICATION_LOGOUT_REQUESTED,
    AUTHENTICATION_PENDING,
    AUTHENTICATION_NOT_PENDING,
    AUTHENTICATION_SUCCEED,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_REQUIRED,
    AUTHENTICATION_PRACTITIONER_REGISTER_REQUESTED,
    AUTHENTICATION_REGISTER_PENDING,
    AUTHENTICATION_REGISTER_NOT_PENDING,
    AUTHENTICATION_REGISTER_FAIL,
    AUTHENTICATION_REGISTER_SUCCEED
} from '../constants/authentication';
import AuthenticationService from '../../service/authentication';

function* authenticateUser(action) {
    try {
        yield put({ type: AUTHENTICATION_PENDING });
        const auth = yield call(AuthenticationService.authenticate);
        const isAuthenticated = auth != null;
        if (isAuthenticated) {
            yield put({ type: AUTHENTICATION_NOT_PENDING });
            yield put({
                type: AUTHENTICATION_SUCCEED,
                payload: {
                    auth: auth
                }
            });
        } else {
            yield put({ type: AUTHENTICATION_NOT_PENDING });
            yield put({
                type: AUTHENTICATION_REQUIRED
            });
        }
    } catch (e) {
        yield put({ type: AUTHENTICATION_NOT_PENDING });
        yield put({
            type: AUTHENTICATION_FAIL,
            payload: { message: 'Failed to login User' }
        });
    }
}

function* loginUser(action) {
    try {
        yield put({ type: AUTHENTICATION_PENDING });
        const auth = yield call(AuthenticationService.login, action.payload.email, action.payload.password);
        const isSuccess = auth != null;
        if (isSuccess) {
            yield put({ type: AUTHENTICATION_NOT_PENDING });
            yield put({
                type: AUTHENTICATION_SUCCEED,
                payload: {
                    auth: auth
                }
            });
        } else {
            yield put({ type: AUTHENTICATION_NOT_PENDING });
            yield put({
                type: AUTHENTICATION_FAIL,
                payload: { message: 'Wrong User Credentials Entered' }
            });
        }
    } catch (e) {
        yield put({ type: AUTHENTICATION_NOT_PENDING });
        yield put({
            type: AUTHENTICATION_FAIL,
            payload: { message: 'Failed to Login User' }
        });
    }
}

function* logoutUser(action) {
    try {
        yield put({ type: AUTHENTICATION_PENDING });
        const success = yield call(AuthenticationService.logout);
        yield put({ type: AUTHENTICATION_NOT_PENDING });
        if (success) {
            yield put({
                type: AUTHENTICATION_REQUIRED
            });
        }
    } catch (e) {}
}

function* registerPractitioner(action) {
    try {
        yield put({ type: AUTHENTICATION_REGISTER_PENDING });
        const result = yield call(AuthenticationService.registerPractitioner, action.payload.email, action.payload.password);
        const { user } = result;
        const isSuccess = user != null;
        if (isSuccess) {
            yield put({
                type: AUTHENTICATION_REGISTER_SUCCEED,
                payload: {}
            });
            yield put({
                type: AUTHENTICATION_LOGIN_REQUESTED,
                payload: {
                    email: action.payload.email,
                    password: action.payload.password
                }
            });
        } else {
            yield put({
                type: AUTHENTICATION_REGISTER_FAIL,
                payload: { message: 'Practitioner not found' }
            });
        }
    } catch (e) {
        yield put({
            type: AUTHENTICATION_REGISTER_FAIL,
            payload: { message: 'Failed to Register Practitioner' }
        });
    }
}

function* AuthenticationSaga() {
    yield takeLatest(AUTHENTICATION_REQUESTED, authenticateUser);
    yield takeLatest(AUTHENTICATION_LOGIN_REQUESTED, loginUser);
    yield takeLatest(AUTHENTICATION_LOGOUT_REQUESTED, logoutUser);
    yield takeLatest(AUTHENTICATION_PRACTITIONER_REGISTER_REQUESTED, registerPractitioner);
}

export default AuthenticationSaga;
