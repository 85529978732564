import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class HumanCareService {
    static getCredit() {
        return axios
            .get(
                Config.api.path + '/v1/human-care/credit/get',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static fetchTransactions() {
        return axios
            .get(
                Config.api.path + '/v1/human-care/credit/transaction/fetch',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static requestCredits() {
        return axios
            .post(
                Config.api.path + '/v1/human-care/credit/request',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                return false;
            });
    }
}

export default HumanCareService;
