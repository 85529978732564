import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class PractitionerService {
    static fetchBlocks(practitioner_id) {
        return axios
            .get(
                Config.api.path + '/v1/practitioner/slot/fetch-blocks',
                { params: { practitioner_id: practitioner_id } },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static saveBlocks(practitioner_id, createItems, updateItems, deleteItems) {
        return axios
            .post(
                Config.api.path + '/v1/practitioner/slot/save-blocks',
                { practitioner_id, createItems, updateItems, deleteItems },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }
}

export default PractitionerService;
