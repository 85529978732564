import { PRACTITIONER_AVAILABILITY_GET_IS_LOADING, PRACTITIONER_AVAILABILITY_GET_COMPLETED } from '../constants/practitioner';

const initialState = {
    availability: {
        is_loading: false,
        data: []
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case PRACTITIONER_AVAILABILITY_GET_IS_LOADING:
            return {
                ...state,
                availability: {
                    data: [],
                    is_loading: true
                }
            };
        case PRACTITIONER_AVAILABILITY_GET_COMPLETED:
            return {
                ...state,
                availability: {
                    is_loading: false,
                    data: action.payload.availability || []
                }
            };

        default:
            return state;
    }
}
