import { call, put, takeLatest } from 'redux-saga/effects';
import { AUTHENTICATION_SUCCEED } from '../constants/authentication';
import {
    HUMAN_CARE_CREDIT_REQUESTED,
    HUMAN_CARE_CREDIT_IS_LOADING,
    HUMAN_CARE_CREDIT_NOT_LOADING,
    HUMAN_CARE_CREDIT_FETCHED,
    HUMAN_CARE_TRANSACTIONS_REQUESTED,
    HUMAN_CARE_TRANSACTIONS_IS_LOADING,
    HUMAN_CARE_TRANSACTIONS_NOT_LOADING,
    HUMAN_CARE_TRANSACTIONS_FETCHED,
    HUMAN_CARE_CREDIT_REQUEST_REQUESTED
} from '../constants/human_care';
import HumanCareService from '../../service/human_care';

function* getCredit(action) {
    try {
        yield put({ type: HUMAN_CARE_CREDIT_IS_LOADING });
        const { credit } = yield call(HumanCareService.getCredit);
        yield put({ type: HUMAN_CARE_CREDIT_NOT_LOADING });
        yield put({
            type: HUMAN_CARE_CREDIT_FETCHED,
            payload: {
                credit: credit
            }
        });
    } catch (e) {
        console.error('[Human Care Saga]', e);
    }
}

function* getTransactions(action) {
    try {
        yield put({ type: HUMAN_CARE_TRANSACTIONS_IS_LOADING });
        const { transactions } = yield call(HumanCareService.fetchTransactions);
        yield put({ type: HUMAN_CARE_TRANSACTIONS_NOT_LOADING });
        yield put({
            type: HUMAN_CARE_TRANSACTIONS_FETCHED,
            payload: {
                transactions: transactions
            }
        });
    } catch (e) {
        console.error('[Human Care Saga]', e);
    }
}

function* requestCredits(action) {
    try {
        yield call(HumanCareService.requestCredits);
        yield put({ type: HUMAN_CARE_CREDIT_REQUESTED });
    } catch (e) {
        console.error('[Human Care Saga]', e);
    }
}

function* HumanCareSaga() {
    yield takeLatest(AUTHENTICATION_SUCCEED, getCredit);
    yield takeLatest(AUTHENTICATION_SUCCEED, getTransactions);

    yield takeLatest(HUMAN_CARE_CREDIT_REQUESTED, getCredit);
    yield takeLatest(HUMAN_CARE_TRANSACTIONS_REQUESTED, getTransactions);
    yield takeLatest(HUMAN_CARE_CREDIT_REQUEST_REQUESTED, requestCredits);
}

export default HumanCareSaga;
