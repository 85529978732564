import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import dashboard from './dashboard';
import humanCare from './human-care';
import statistics from './statistics';
import practitioner from './practitioner';

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = () => {
    // items: [dashboard, content, humanSupport, resources]
    const auth = useSelector((state) => state.authentication.auth);
    const is_practitioner = auth?.user?.practitioner_id != null;
    if (is_practitioner) {
        return {
            items: [practitioner]
        };
    }
    return {
        items: [statistics, humanCare]
    };
};

export default MenuItems;
