import { fork } from 'redux-saga/effects';

import AuthenticationSaga from './authentication';
import SettingSaga from './setting';
import HumanCareSaga from './human_care';
import StatisticsSaga from './statistics';
import PractitionerSaga from './practitioner';

export default function* saga() {
    yield fork(AuthenticationSaga);
    yield fork(SettingSaga);
    yield fork(HumanCareSaga);
    yield fork(StatisticsSaga);
    yield fork(PractitionerSaga);
}
