import {
    AUTHENTICATION_REQUIRED,
    AUTHENTICATION_REQUESTED,
    AUTHENTICATION_LOGOUT_REQUESTED,
    AUTHENTICATION_LOGIN_REQUESTED,
    AUTHENTICATION_PENDING,
    AUTHENTICATION_NOT_PENDING,
    AUTHENTICATION_SUCCEED,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_REGISTER_PENDING,
    AUTHENTICATION_REGISTER_FAIL,
    AUTHENTICATION_REGISTER_SUCCEED
} from '../constants/authentication';

const initialState = {
    auth: null,
    isAuthenticated: false,
    isLoading: false,
    error: null,
    isLoadingRegister: false
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case AUTHENTICATION_REQUIRED:
            return {
                ...state,
                isAuthenticated: false,
                auth: null,
                isLoading: false,
                error: null
            };
            break;
        case AUTHENTICATION_REQUESTED:
            break;
        case AUTHENTICATION_LOGIN_REQUESTED:
            break;
        case AUTHENTICATION_LOGOUT_REQUESTED:
            break;
        case AUTHENTICATION_PENDING:
            return {
                ...state,
                isLoading: true
            };
        case AUTHENTICATION_NOT_PENDING:
            return {
                ...state,
                isLoading: false
            };
        case AUTHENTICATION_SUCCEED:
            return {
                ...state,
                isAuthenticated: true,
                auth: action.payload.auth,
                error: null
            };
            break;
        case AUTHENTICATION_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                auth: null,
                error: {
                    message: action.payload.message
                }
            };
            break;
        case AUTHENTICATION_REGISTER_PENDING:
            return {
                ...state,
                isLoadingRegister: true
            };
        case AUTHENTICATION_REGISTER_SUCCEED:
            return {
                ...state,
                isLoadingRegister: false
            };
        case AUTHENTICATION_REGISTER_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                auth: null,
                error: {
                    message: action.payload.message
                },
                isLoadingRegister: false
            };
        default:
            return state;
    }
    return state;
}
