import { call, put, takeLatest } from 'redux-saga/effects';
import PractitionerService from '../../service/practitioner';
import {
    PRACTITIONER_AVAILABILITY_GET_COMPLETED,
    PRACTITIONER_AVAILABILITY_GET_IS_LOADING,
    PRACTITIONER_AVAILABILITY_GET_REQUESTED,
    PRACTITIONER_AVAILABILITY_SAVE_REQUESTED
} from 'redux/constants/practitioner';
import { toast } from 'react-toastify';

function* fetchPractitionerAvailability(action) {
    try {
        const {
            payload: { practitioner_id }
        } = action;
        yield put({ type: PRACTITIONER_AVAILABILITY_GET_IS_LOADING });
        const result = yield call(PractitionerService.fetchBlocks, practitioner_id);
        if (result != null) {
            yield put({
                type: PRACTITIONER_AVAILABILITY_GET_COMPLETED,
                payload: {
                    availability: result
                }
            });
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: PRACTITIONER_AVAILABILITY_GET_COMPLETED,
            payload: {
                availability: []
            }
        });
    }
}

function* savePractitionerAvailability(action) {
    try {
        const {
            payload: { practitioner_id, createItems, updateItems, deleteItems }
        } = action;

        yield put({ type: PRACTITIONER_AVAILABILITY_GET_IS_LOADING });
        const { result } = yield call(PractitionerService.saveBlocks, practitioner_id, createItems, updateItems, deleteItems);
        //
        const errorMessages = result?.filter((res) => !res.success)?.map((err) => err.message);
        for (const msg of errorMessages) {
            toast.error(msg);
        }
        yield put({
            type: PRACTITIONER_AVAILABILITY_GET_REQUESTED,
            payload: {
                practitioner_id
            }
        });
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: PRACTITIONER_AVAILABILITY_GET_REQUESTED,
            payload: {
                practitioner_id
            }
        });
    }
}

function* PractitionerSaga() {
    // Get detail
    yield takeLatest(PRACTITIONER_AVAILABILITY_GET_REQUESTED, fetchPractitionerAvailability);
    yield takeLatest(PRACTITIONER_AVAILABILITY_SAVE_REQUESTED, savePractitionerAvailability);
}

export default PractitionerSaga;
