import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class StatisticsService {
    static getOrganizationDetail(organization_id) {
        return axios
            .get(
                Config.api.path + '/v1/organization/get_detail',
                {
                    params: {
                        organization_id: organization_id
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static getRegisteredStatistics(organization_id, from_date, to_date) {
        return axios
            .get(
                Config.api.path + '/v1/organization/statistics/registered',
                {
                    params: { organization_id, from_date, to_date }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static getMindHealthScoreStatistics(organization_id, from_date, to_date) {
        return axios
            .get(
                Config.api.path + '/v1/organization/statistics/mind_health',
                {
                    params: { organization_id, from_date, to_date }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static getSupportEngagedStatistics(organization_id, from_date, to_date) {
        return axios
            .get(
                Config.api.path + '/v1/organization/statistics/support_engaged',
                {
                    params: { organization_id, from_date, to_date }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static getCareCreditsStatistics(organization_id, from_date, to_date) {
        return axios
            .get(
                Config.api.path + '/v1/human-care/credit/get',
                {
                    params: { organization_id, from_date, to_date }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }
}

export default StatisticsService;
