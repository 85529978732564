export const AUTHENTICATION_REQUIRED = 'authentication/REQUIRED';
export const AUTHENTICATION_REQUESTED = 'authentication/REQUESTED';
export const AUTHENTICATION_LOGOUT_REQUESTED = 'authentication/logout/REQUESTED';
export const AUTHENTICATION_LOGIN_REQUESTED = 'authentication/login/REQUESTED';
export const AUTHENTICATION_PENDING = 'authentication/PENDING';
export const AUTHENTICATION_NOT_PENDING = 'authentication/NOT_PENDING';
export const AUTHENTICATION_SUCCEED = 'authentication/SUCCEED';
export const AUTHENTICATION_FAIL = 'authentication/FAIL';
export const AUTHENTICATION_PRACTITIONER_REGISTER_REQUESTED = 'authentication/practitioner/REGISTER/REQUESTED';
export const AUTHENTICATION_REGISTER_PENDING = 'authentication/register/PENDING';
export const AUTHENTICATION_REGISTER_NOT_PENDING = 'authentication/register/NOT_PENDING';
export const AUTHENTICATION_REGISTER_SUCCEED = 'authentication/register/SUCCEED';
export const AUTHENTICATION_REGISTER_FAIL = 'authentication/register/FAIL';
