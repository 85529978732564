import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import NavigationReducer from './navigation';
import AuthenticationReducer from './authentication';
import SettingReducer from './setting';
import HumanCareReducer from './human_care';
import CustomizationReducer from './view/customizationReducer';
import StatisticsReducer from './statistics';
import PractitionerReducer from './practitioner';
import { history } from '../../history';

const viewReducer = combineReducers({
    customization: CustomizationReducer
});

const dataReducer = combineReducers({
    setting: SettingReducer,
    human_care: HumanCareReducer,
    statistics: StatisticsReducer,
    practitioner: PractitionerReducer
});

export default combineReducers({
    router: connectRouter(history),
    navigation: NavigationReducer,
    authentication: AuthenticationReducer,
    view: viewReducer,
    data: dataReducer
});
