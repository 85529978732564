import {
    AUTHENTICATION_REQUESTED,
    AUTHENTICATION_LOGIN_REQUESTED,
    AUTHENTICATION_LOGOUT_REQUESTED,
    AUTHENTICATION_PRACTITIONER_REGISTER_REQUESTED
} from '../constants/authentication';

// Action Creators
export function authenticate() {
    return {
        type: AUTHENTICATION_REQUESTED
    };
}

export function login(email, password, asPractitioner = false) {
    return {
        type: AUTHENTICATION_LOGIN_REQUESTED,
        payload: {
            email: email,
            password: password,
            asPractitioner
        }
    };
}

export function logout() {
    return {
        type: AUTHENTICATION_LOGOUT_REQUESTED
    };
}

export function registerPractitioner(email, password, asPractitioner = false) {
    return {
        type: AUTHENTICATION_PRACTITIONER_REGISTER_REQUESTED,
        payload: {
            email: email,
            password: password,
            asPractitioner
        }
    };
}
