import { call, put, takeLatest } from 'redux-saga/effects';
import {
    CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
    CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_REQUESTED,
    CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED,
    CUSTOMER_ORGANIZATION_CARE_CREDITS_REQUESTED,
    CUSTOMER_ORGANIZATION_GET_COMPLETED,
    CUSTOMER_ORGANIZATION_GET_IS_LOADING,
    CUSTOMER_ORGANIZATION_GET_NOT_LOADING,
    CUSTOMER_ORGANIZATION_GET_REQUESTED,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_IS_LOADING,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_REQUESTED,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_REQUESTED
} from 'redux/constants/statistics';
import StatisticsService from '../../service/statistics';

function* fetchOrganizationDetail(action) {
    try {
        const {
            payload: { organization_id }
        } = action;
        yield put({ type: CUSTOMER_ORGANIZATION_GET_IS_LOADING });
        const results = yield call(StatisticsService.getOrganizationDetail, organization_id);
        yield put({ type: CUSTOMER_ORGANIZATION_GET_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CUSTOMER_ORGANIZATION_GET_COMPLETED,
                payload: {
                    organization: results.organization
                }
            });
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_GET_COMPLETED,
            payload: {
                organization: null
            }
        });
    }
}

function* getAccountsRegistered(action) {
    const {
        payload: { organization_id, fromDate, toDate }
    } = action;
    if (!organization_id) return;
    try {
        const result = yield call(StatisticsService.getRegisteredStatistics, organization_id, fromDate, toDate);
        if (result != null) {
            yield put({
                type: CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
                payload: {
                    organization_id,
                    accounts_registered: result
                }
            });
        } else {
            yield put({
                type: CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
                payload: {
                    organization_id,
                    accounts_registered: {}
                }
            });
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
            payload: {
                organization_id,
                accounts_registered: {}
            }
        });
    }
}

function* getMindHealthScoreStatistics(action) {
    try {
        const {
            payload: { organization_id, fromDate, toDate }
        } = action;
        yield put({
            type: CUSTOMER_ORGANIZATION_MIND_HEALTH_IS_LOADING
        });
        const result = yield call(StatisticsService.getMindHealthScoreStatistics, organization_id, fromDate, toDate);
        if (result != null) {
            yield put({
                type: CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
                payload: {
                    mind_health_score: result
                }
            });
        } else {
            yield put({
                type: CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
                payload: {
                    mind_health_score: null
                }
            });
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
            payload: {
                mind_health_score: null
            }
        });
    }
}

function* getSupportEngagedStatistics(action) {
    try {
        const {
            payload: { organization_id, fromDate, toDate }
        } = action;
        yield put({
            type: CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING
        });
        const result = yield call(StatisticsService.getSupportEngagedStatistics, organization_id, fromDate, toDate);
        yield put({
            type: CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED,
            payload: {
                support_engaged: result
            }
        });
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED,
            payload: {
                support_engaged: null
            }
        });
    }
}

function* getCareCreditsStatistics(action) {
    try {
        const {
            payload: { organization_id, fromDate, toDate }
        } = action;
        const result = yield call(StatisticsService.getCareCreditsStatistics, organization_id, fromDate, toDate);
        yield put({
            type: CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED,
            payload: {
                care_credit: result?.credit
            }
        });
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED,
            payload: {
                care_credit: null
            }
        });
    }
}

function* ClientStatisticsSaga() {
    // Get detail
    yield takeLatest(CUSTOMER_ORGANIZATION_GET_REQUESTED, fetchOrganizationDetail);

    // Statistics
    yield takeLatest(CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_REQUESTED, getAccountsRegistered);
    yield takeLatest(CUSTOMER_ORGANIZATION_MIND_HEALTH_REQUESTED, getMindHealthScoreStatistics);
    yield takeLatest(CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_REQUESTED, getSupportEngagedStatistics);
    yield takeLatest(CUSTOMER_ORGANIZATION_CARE_CREDITS_REQUESTED, getCareCreditsStatistics);
}

export default ClientStatisticsSaga;
