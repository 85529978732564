// assets
import { IconCoin } from '@tabler/icons';

// constant
const icons = {
    IconCoin
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const humanCare = {
    id: 'human-care',
    title: 'Human Care',
    type: 'group',
    children: [
        {
            id: 'care-credit',
            title: 'Credits',
            type: 'item',
            url: '/human-care/credit',
            icon: icons.IconCoin
        }
    ]
};

export default humanCare;
