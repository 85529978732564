export const CUSTOMER_ORGANIZATION_GET_REQUESTED = 'customer/ORGANIZATION_GET_REQUESTED';
export const CUSTOMER_ORGANIZATION_GET_IS_LOADING = 'customer/ORGANIZATION_GET_IS_LOADING';
export const CUSTOMER_ORGANIZATION_GET_NOT_LOADING = 'customer/ORGANIZATION_GET_NOT_LOADING';
export const CUSTOMER_ORGANIZATION_GET_COMPLETED = 'customer/ORGANIZATION_GET_COMPLETED';

export const CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_REQUESTED = 'customer/ORGANIZATION_ACCOUNTS_REGISTERED_REQUESTED';
export const CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED = 'customer/ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED';

export const CUSTOMER_ORGANIZATION_MIND_HEALTH_REQUESTED = 'customer/ORGANIZATION_MIND_HEALTH_REQUESTED';
export const CUSTOMER_ORGANIZATION_MIND_HEALTH_IS_LOADING = 'customer/ORGANIZATION_MIND_HEALTH_IS_LOADING';
export const CUSTOMER_ORGANIZATION_MIND_HEALTH_NOT_LOADING = 'customer/ORGANIZATION_MIND_HEALTH_NOT_LOADING';
export const CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED = 'customer/ORGANIZATION_MIND_HEALTH_FETCHED';

export const CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_REQUESTED = 'customer/ORGANIZATION_SUPPORT_ENGAGED_REQUESTED';
export const CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING = 'customer/ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING';
export const CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED = 'customer/ORGANIZATION_SUPPORT_ENGAGED_FETCHED';

export const CUSTOMER_ORGANIZATION_CARE_CREDITS_REQUESTED = 'customer/ORGANIZATION_CARE_CREDITS_REQUESTED';
export const CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED = 'customer/ORGANIZATION_CARE_CREDITS_FETCHED';
