export const HUMAN_CARE_CREDIT_REQUESTED = 'human_care/credit/REQUESTED';
export const HUMAN_CARE_CREDIT_IS_LOADING = 'human_care/credit/IS_LOADING';
export const HUMAN_CARE_CREDIT_NOT_LOADING = 'human_care/credit/NOT_LOADING';
export const HUMAN_CARE_CREDIT_FETCHED = 'human_care/credit/FETCHED';

export const HUMAN_CARE_TRANSACTIONS_REQUESTED = 'human_care/transactions/REQUESTED';
export const HUMAN_CARE_TRANSACTIONS_IS_LOADING = 'human_care/transactions/IS_LOADING';
export const HUMAN_CARE_TRANSACTIONS_NOT_LOADING = 'human_care/transactions/NOT_LOADING';
export const HUMAN_CARE_TRANSACTIONS_FETCHED = 'human_care/transactions/FETCHED';

export const HUMAN_CARE_CREDIT_REQUEST_REQUESTED = 'human_care/credit/request/REQUESTED';
