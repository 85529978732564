import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class AuthenticationService {
    static authenticate() {
        return axios
            .get(
                Config.api.path + '/auth/login/',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static login(email, password) {
        return axios
            .post(Config.api.path + '/login/', { email: email, password: password }, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static logout() {
        return axios
            .post(Config.api.path + '/logout/', {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return true;
            })
            .catch((error) => {
                return false;
            });
    }

    static hasAccess() {
        return axios
            .get(Config.api.path + '/user/has_access', {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return false;
            });
    }

    static resetPassword(token, password) {
        return axios
            .post(
                Config.api.path + '/v1/authentication/password/reset',
                { token: token, password: password },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data?.success;
            })
            .catch((error) => {
                console.error('[Auth Error] ' + error);
                return false;
            });
    }

    static registerPractitioner(email, password) {
        return axios
            .post(
                Config.api.path + '/v1/practitioner/register',
                { email: email, password: password },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }
}

export default AuthenticationService;
