const config = {
    api: {
        path: 'https://company.api.neurumhealth.com'
        // path: 'https://company.api.dev.neurumhealth.com'
        // path: 'http://localhost:3600'
        // path: 'https://7488-50-97-232-186.ngrok.io'
        // path: "https://code.neurumhealth.com:3500"
    },
    basename: '',
    defaultPath: '/',
    fontFamily: `'Inter', sans-serif`,
    borderRadius: 20
};

export default config;
